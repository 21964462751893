<template>
<layout-default v-if="itLoads">
  <div class="layout__privacy">
    <div class="container-fluid position-relative overflow-hidden">
      <div class="row">
        <div class="col-12 offset-lg-1 col-lg-5 offset-xl-1 col-xl-4 mt-10">
          <h1>{{pageData.title.rendered}}</h1>
        </div>
      </div>
    </div>
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col-12 offset-lg-1 col-lg-8 offset-xl-1 col-xl-8">
          <div v-html="pageData.content.rendered"></div>
        </div>
      </div>
    </div>
  </div>
  </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault';
export default {
  inject:['MyLoading'],
  components: {LayoutDefault },
  data() {
    return {
      pageData: null,
      itLoads: false
    };
  },
  head () {
    return {
      title: this.pageData._yoast_wpseo_title,
      meta: [
        { vmid: 'description', name: 'description', content: this.pageData._yoast_wpseo_metadesc }
      ]
    }
  },
   methods: {
    fetchingData() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp/v2/pages/3")
        .then(response => {
          this.pageData = response.data;
          this.itLoads = true;
          this.MyLoading.val = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
   },
   mounted() {
    this.fetchingData();
  },
  created() {
    this.MyLoading.val = true;
  }
};
</script>

<style>
</style>